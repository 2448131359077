import { template as template_13f7b467bcc24e419b2803cc454d80b5 } from "@ember/template-compiler";
const WelcomeHeader = template_13f7b467bcc24e419b2803cc454d80b5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
