import { template as template_988c0a7efc92482198aef5e3ccab6fc4 } from "@ember/template-compiler";
const FKText = template_988c0a7efc92482198aef5e3ccab6fc4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
