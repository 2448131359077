import { template as template_63d33d7cf5c842c5adadb6e2bd9d03c7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_63d33d7cf5c842c5adadb6e2bd9d03c7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
