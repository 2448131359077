import { template as template_0463d43076b74e6e9754ff511065222d } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_0463d43076b74e6e9754ff511065222d(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
