import { template as template_a765a06900a94de6b1289fc18ad1f756 } from "@ember/template-compiler";
const FKControlMenuContainer = template_a765a06900a94de6b1289fc18ad1f756(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
